export const JUSTIFY_CONTENT_OPTIONS = {
  start: 'justify-start',
  right: 'justify-end',
  center: 'justify-center',

  middle: 'justify-center',
  bottom: 'justify-end',
  top: 'justify-start',
}

export const ALIGN_ITEMS_OPTIONS = {
  top: 'items-start',
  bottom: 'items-end',
  middle: 'items-center',

  start: 'items-start',
  right: 'items-end',
  center: 'items-center',
}

export const TEXT_ALIGNMENT = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
  justify: 'text-justify',
}